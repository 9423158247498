// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-container1{
    width: 50%;
    height: 600px;
}

.each-fade1{

    background-color: #fff;
}


.leftText{
    text-align: justify;

    /* position: absolute; */
}

.image1 {
    display: block;
    width: 400px;
    height: 600px;
    margin: auto;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.react-slideshow-container .default-nav:first-of-type {
    opacity: 0;
}

.react-slideshow-container .default-nav:last-of-type {
    opacity: 0;
}

@media screen and (max-width: 960px) {
    .slide-container1{
        height: 400px;
    }

    .react-slideshow-fadezoom-wrapper {
        width: 960px;
        
    }

    

    .image1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 400px;
        width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Slideshowgarcia.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;;IAEI,sBAAsB;AAC1B;;;AAGA;IACI,mBAAmB;;IAEnB,wBAAwB;AAC5B;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kDAAkD;AACtD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;;IAEhB;;;;IAIA;QACI,cAAc;QACd,iBAAiB;QACjB,kBAAkB;QAClB,aAAa;QACb,YAAY;IAChB;AACJ","sourcesContent":[".slide-container1{\n    width: 50%;\n    height: 600px;\n}\n\n.each-fade1{\n\n    background-color: #fff;\n}\n\n\n.leftText{\n    text-align: justify;\n\n    /* position: absolute; */\n}\n\n.image1 {\n    display: block;\n    width: 400px;\n    height: 600px;\n    margin: auto;\n    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;\n}\n\n.react-slideshow-container .default-nav:first-of-type {\n    opacity: 0;\n}\n\n.react-slideshow-container .default-nav:last-of-type {\n    opacity: 0;\n}\n\n@media screen and (max-width: 960px) {\n    .slide-container1{\n        height: 400px;\n    }\n\n    .react-slideshow-fadezoom-wrapper {\n        width: 960px;\n        \n    }\n\n    \n\n    .image1 {\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n        height: 400px;\n        width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
