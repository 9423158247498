import React from 'react';
import './CardsGarcia.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1 className='header'>Our Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/heelsurgery.webp'
              text='Heel Pain'
              path='https://www.southfloridasportsmedicine.com/heel-spur'
            />
            <CardItem
              src='images/arthroscopy.webp'
              text='Arthroscopy'
              path='https://www.southfloridasportsmedicine.com/arthroscopy-of-the-foot-a-ankle-page-2'
            />
            <CardItem
              src='images/ankleinjuries-.webp'
              text='Ankle Injuries'
              path='https://www.southfloridasportsmedicine.com/ankles'
            />
            <CardItem
              src='images/flatfoot.webp'
              text='Flat Feet'
              path='https://www.southfloridasportsmedicine.com/flat-feet-adult'
            />
            <CardItem
              src='images/higharches.webp'
              text='High Arches'
              path='https://www.southfloridasportsmedicine.com/cavus'
            />
            <CardItem
              src='images/bunions.webp'
              text='Bunions'
              path='https://www.southfloridasportsmedicine.com/bunions'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;