import React from 'react';
import '../../App.css';
import CardsGarcia from '../CardsGarcia';
import HeroSectionGarcia from '../HeroSectionGarcia';
import Footer from '../Footer';
import InsuranceSection from '../InsuranceSection';
import SlideshowFRGarcia from '../SlideshowFRGarcia.js';
import SlideshowFR2Garcia from '../SlideshowFR2Garcia.js';

function Team() {
  return (
    <>
      <HeroSectionGarcia />
     
      <InsuranceSection/>
      <SlideshowFRGarcia doctorName="Dr. Christina Pena Garcia" doctorLink='https://www.healthgrades.com/providers/christina-pena-garcia-xynwgdb' doctorPosition="D.P.M.Foot and Ankle Surgeon" imgsrc='./images/garcia.webp' doctorInfo={"Christina Pena Garcia, DPM is a fellowship trained Podiatric Surgeon, board qualified in Foot Surgery. Dr. Garcia treats all ailments of the foot and ankle in adults, children, and teens. Her specialized interests include surgical and conservative treatment of foot deformities, foot fractures, sprains, and injuries\n\n" + "Dr. Garcia received her undergraduate education from Florida State University with a Bachelor’s in Exercise Science. She went on to obtain her Master’s Degree in Biomedical Science at Barry University. While obtaining her doctoral degree Dr. Garcia participated in research for diabetic limb salvage and was the recipient of the John Steinberg Limb Salvage Award upon graduation from Barry University’s School of Podiatric Medicine. She then went on to complete her surgical residency training, with added credential in Reconstructive Rearfoot/Ankle Surgery, at Memorial Healthcare System in Broward County. During residency Dr. Garcia completed numerous additional surgical training courses for both internal and external fixation, arthroscopy, limb salvage, and trauma. After residency Dr. Garcia felt her passion was in Sports Medicine and therefore completed a Fellowship in Sports Medicine and Rearfoot Reconstruction. During this time she was able to receive specialized training in treating sports injuries and had the opportunity to lecture at conferences on this topic\n\n"}/>
     
      {/* <Introduction/> */}
      <CardsGarcia />
      <SlideshowFR2Garcia doctorName={"Follow Us on Instagram!\n(@drchristinagarciadpm)"}/>
      <Footer />
    </>
  );
}

export default Team;