import 'react-slideshow-image/dist/styles.css'
import './Slideshow2.css'
import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Fade } from 'react-slideshow-image';


const FadeExample = (props) => {
  const fadeImages = [
    "./images/3.webp"
  ];

  return (
    <div className="slide-container2">
      <div className="slide-wrapper2">
          <Fade>
            <div className="each-fade2">
              <div>
                <img className='image2' src={fadeImages[0]} />
              </div>
            </div>
            {/* <div className="each-fade2">
              <div>
                <img className='image2'  src={fadeImages[1]} />
              </div>
            </div>
            <div className="each-fade2">
              <div>
                <img className='image2' src={fadeImages[2]} />
              </div>
            </div>
            <div className="each-fade2">
              <div>
                <img className='image2' src={fadeImages[3]} />
              </div>
            </div> */}
          </Fade>
        </div>
        </div>

  );
};

export default FadeExample;