import React from 'react';
import '../App.css';
import './TeamSectionGarcia.css'
import Slideshow from './Slideshowgarcia.js'
 

export const SlideshowFR = (props) => {
    return (
        <div className='team-container1'>
            <Slideshow/>
            <div className='team-wrapper1'>
                <h1 className='doctorName'>{props.doctorName} <br></br> {props.doctorPosition}</h1>
                <br></br>
                <p className='doctorInfo'>{props.doctorInfo}</p>
                {/* <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr> */}
            </div>
            
        </div>
    )
}

export default SlideshowFR;