import 'react-slideshow-image/dist/styles.css'
import './Slideshowgarcia.css'
import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Fade } from 'react-slideshow-image';


const FadeExample = (props) => {
  const fadeImages = [
    "./images/1.jpg",
    "./images/2.jpg",
    "./images/4.jpg",
    "./images/5.jpg",
  ];

  return (
    <div className="slide-container1">
          <Fade arrows={false} className="fade">
            <div className="each-fade101">
              <div>
                <img className='image1' src={fadeImages[0]} />
              </div>
            </div>
            <div className="each-fade1">
              <div>
                <img className='image1'  src={fadeImages[1]} />
              </div>
            </div>
            <div className="each-fade1">
              <div>
                <img className='image1' src={fadeImages[2]} />
              </div>
            </div>
            <div className="each-fade1">
              <div>
                <img className='image1' src={fadeImages[3]} />
              </div>
            </div>
          </Fade>
        </div>
  );
};

export default FadeExample;