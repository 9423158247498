// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.slide-container2{
    width: 50%;
    height: 500px;
}

.slider-wrapper2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.each-fade2{
    background-color: #fff;
    width: 100%;
    display: flex;
}

.leftText{
    text-align: justify;
    margin-right: 20px;
}

.image2 {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.react-slideshow-container .default-nav:first-of-type {
    opacity: 0;
}

.react-slideshow-container .default-nav:last-of-type {
    opacity: 0;
}

@media screen and (max-width: 960px) {
    .image2 {
        width: 300px;
        height: 300px;
    }

    .slide-container2{
        width: 100%;
        height: 100%;
    }

    .each-fade2{
        /* margin-left: 30px; */
        background-color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/Slideshow2.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kDAAkD;AACtD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,uBAAuB;QACvB,sBAAsB;QACtB,WAAW;QACX,aAAa;QACb,mBAAmB;QACnB,uBAAuB;IAC3B;;AAEJ","sourcesContent":["\n.slide-container2{\n    width: 50%;\n    height: 500px;\n}\n\n.slider-wrapper2{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.each-fade2{\n    background-color: #fff;\n    width: 100%;\n    display: flex;\n}\n\n.leftText{\n    text-align: justify;\n    margin-right: 20px;\n}\n\n.image2 {\n    display: block;\n    width: 100%;\n    height: 100%;\n    margin: auto;\n    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;\n}\n\n.react-slideshow-container .default-nav:first-of-type {\n    opacity: 0;\n}\n\n.react-slideshow-container .default-nav:last-of-type {\n    opacity: 0;\n}\n\n@media screen and (max-width: 960px) {\n    .image2 {\n        width: 300px;\n        height: 300px;\n    }\n\n    .slide-container2{\n        width: 100%;\n        height: 100%;\n    }\n\n    .each-fade2{\n        /* margin-left: 30px; */\n        background-color: #fff;\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
