import React from 'react';
import '../App.css';
import './TeamSection2Garcia.css'
import Slideshow from './Slideshow2garcia.js'
 

export const SlideshowFR = (props) => {
    return (
        <div className='team-container2'>
            <div className='team-wrapper2'>
                <a href="https://www.instagram.com/drchristinagarciadpm/" target="_blank"><h1 className='doctorName'>{props.doctorName} <br></br> {props.doctorPosition}</h1></a>
                <br></br>
                <p className='doctorInfo'>{props.doctorInfo}</p>
                {/* <hr style={{height:'1px', 'border-width':0, color:'#D0E3FF', 'background-color': '#D0E3FF'}}></hr> */}
            </div>
            <Slideshow/>
        </div>
    )
}

export default SlideshowFR;